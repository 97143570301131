import { render, staticRenderFns } from "./HelloWorld.vue?vue&type=template&id=cdb5f796&scoped=true&"
import script from "./HelloWorld.vue?vue&type=script&lang=ts&"
export * from "./HelloWorld.vue?vue&type=script&lang=ts&"
import style0 from "./HelloWorld.vue?vue&type=style&index=0&id=cdb5f796&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdb5f796",
  null
  
)

export default component.exports